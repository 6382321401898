import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Probability and Statistics`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PZzlGNWx3bUZpMEk"
      }}>{`Download: Applied statistics and probability for engineers by Douglas C. Montgomery and George C. Runger 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PMTd5MVQ5SkhBUkU"
      }}>{`Download: Applied statistics and probability for engineers by Douglas C. Montgomery and George C. Runger 6th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1H_Ma8BJcA-tFQF8EZCJXuypmmLAL4N-Z"
      }}>{`Download: Probability and statistics for engineers and scientists by Ronald E. Walpole, Raymond H. Mayers, Sharon L. Mayers and Keying Ye 8th ed.  `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1rtu0PJRHsnln495B-7lDD8Cks5if2GnA"
      }}>{`Download: Solution Manual Probability and statistics for engineers and scientists by Ronald E. Walpole, Raymond H. Mayers, Sharon L. Mayers and Keying Ye 8th ed.  `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-Pb2xnS3JIcTBTdTQ"
      }}>{`Download: Probability and statistics for engineers and scientists by Sheldon Ross 4th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-Pd1JkS1Jmdk0zVms"
      }}>{`Download: Introduction to statistical theory by Prof. Sher Muhammad Chaudhry, Prof. Dr. Shahid Kamal Ch 6 Probability `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PYTlHbEVEWXNZbWM"
      }}>{`Download: Solution manual Introduction to statistical theory by Prof. Sher Muhammad Chaudhry, Prof. Dr. Shahid Kamal Ch 6 Probability`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PWXZUUWl2MUstUk0"
      }}>{`Download: Introduction to statistical theory by Prof. Sher Muhammad Chaudhry, Prof. Dr. Shahid Kamal Ch 7 Random Variables`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PYlZoSk9rbHdMWlU"
      }}>{`Download: Solution Manual Introduction to statistical theory by Prof. Sher Muhammad Chaudhry, Prof. Dr. Shahid Kamal Ch 7 Random Variables`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PNURlelphb051Y0k"
      }}>{`Download: Introduction to statistical theory by Prof. Sher Muhammad Chaudhry, Prof. Dr. Shahid Kamal Ch 8 Discrete probability distribution `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PVFN5WVNESDhuWGc"
      }}>{`Download: Solution Manual Introduction to statistical theory by Prof. Sher Muhammad Chaudhry, Prof. Dr. Shahid Kamal Ch 8 Discrete probability distribution `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      